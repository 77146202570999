.apps-list-page .header-app-icon {
    width: 40px;
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 5px;
    height: 40px;
  }

a:hover {
  text-decoration: none !important;
}