body {
  height: auto;
  overflow: auto;
}
.m-h-100{
  min-height: 100vh;
}
#header1 {
  background: #684c96;
  padding:10px 0;
  .collapse-nav {
    padding-bottom: 0;
    border-bottom: none;
  }
  .logo {
    max-width: 100px;
  }
  ul{
     margin-left: 0;
  }
}

.inner-pages {
  width: 100%;
  // background: #fff;
  padding: 30px 0;
  h2.pageName {
    color: #684c96;
    font-size: 34px;
  } 
  p{
    font-size: 16px;
    margin-bottom: 15px;
    line-height: 1.5;
    // text-align: justify;
  }
 a{
   color: #684c96;
   text-decoration: underline;
 }
 ul{
   margin-left: 20px; 
   @media screen and (max-width:767px){
     margin-left: 0;
   }
   li{
    margin-bottom: 15px;
    @media screen and (max-width:767px){
      margin-bottom: 5px;
    }
   }
 }
 h2.sub-heading {
  color: #2d1e52;
  font-size: 24px;
  margin-top: 30px;
}
}