#sidebar {
    .side-nav {
        background: #684c96 !important;
        padding: 15px;
        width: 140px;
        border-right: 0;
        z-index: 1000;
        .logo {
            width: 90px;
        }
        .nav-menu {
            .no-arrow {
                text-align: center;
            }
            .link {
                background-color: #684c96;
                color: #fff;
                width: auto;
                border-radius: 10px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .icon {
                    color: #967bb2;
                    padding: 8px;
                    background: #5c4388;
                    width: 40px !important;
                    height: 40px !important;
                    border-radius: 10px;
                }
                .sub-menu {
                    margin-left: 36px;
                    border-left: 3px solid #321455;
                    padding-left: 10px;
                    li {
                        margin-top: 10px;
                        border-bottom: 1px solid #321455;
                        padding-bottom: 8px;
                        text-align: left;
                        .link.active {
                            background: transparent !important;
                        }
                    }
                }
               .sidebar-submenu {
                    position: relative;
                    width: 174px;
                    .dropdown-toggle{
                        text-align: left;
                        padding-left: 0;
                        &::after{
                                border-top: 6px solid;
                                border-right: 6px solid transparent;
                                border-left: 6px solid transparent;
                                right: 20px;
                        }
                    }
                    .dropdown-menu{
                      .dropdown-item{
                        padding-left: 6px !important;
                        font-size: 14px;
                        color: #fff;
                      }
                    }
                }
            }
            li {
                button {
                    width: auto;
                    background: none;
                    padding: 0;
                    margin: 14px 8px;
                    &:active,
                    &:focus {
                        background: none;
                    }
                }
            }
        }
        .nav-menu li .link.active {
            background: #321455 !important;
            .icon {
                color: #fff;
                background: #321455;
            }
        }
        .nav-menu li .link:hover .nav-text {
            display: block;
            position: fixed;
            left: 60px;
            background: #5c4388;
            padding: 8px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            width: 180px;
        }
        .nav-menu li .link.active:hover .nav-text {
            background: #321455;
        }
        .toogle_btn {
            background: transparent;
            text-align: right;
            border: none;
            margin-top: 10px;
            padding-bottom: 5px;
            border-bottom: 1px solid #8670ab;
            .close_menu {
                display: none;
            }
        }
        .nav-menu {
            padding-top: 20px;
            padding-right: 20px;
            li {
                // text-align: center;
                .nav-text {
                    display: none;
                    margin-left: 20px;
                    text-align: left;
                }
            }
        }
    }
    .user-name {
        color: #fff;
        font-size: 18px;
        padding-top: 15px;
        border-top: 1px solid #8670ab;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .scrollbar div::-webkit-scrollbar {
        display: none;
    }
}

/* expand menu style */
.sideMenu {
    #sidebar {
        .side-nav {
            width: 250px;
            .user-name {
                width: 220px;
            }
            .logo {
                margin: auto;
            }
            .nav-menu {
                li {
                    button,
                    .link {
                        width: 100%;
                    }
                    .nav-text {
                        display: block;
                        margin-left: 10px;
                    }
                    .link:hover .nav-text {
                        display: block;
                        position: relative;
                        left: auto;
                        background: transparent;
                        padding: 0;
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        width: auto;
                    }
                }
            }
        }
        .toogle_btn {
            .close_menu {
                display: inline-block;
            }
            .burgure_menu {
                display: none;
            }
        }
    }
    .app-container.mini-drawer .app-sidebar {
        width: 250px;
        min-width: 250px;
        max-width: 250px;
    }
}
#body #sidebar .collapse.show {
    display: none;
}
#body.sideMenu.change #sidebar .collapse.show {
    display: block;
}
/* expand menu style end */

.app-main-header {
    background-color: #684c96;
}
.app-header {
    background: #684c96;
    min-height: 120px;
    padding-top: 15px;
    padding-bottom: 15px;
    position: fixed;
    z-index: 1050;
    width: calc(100% - 0px - 140px);
    top: 0;
    // position: fixed;
    // width: 93%;
    // z-index: 9999;
    .select_organisation {
        background: #5c4388;
        padding-top: 15px;
        margin-right: 15px;
        padding-bottom: 15px;
        border-radius: 10px;
        font-size: 18px;
        label {
            color: #fff;
            white-space: nowrap;
        }
        .select {
            .css-1szy77t-control,
            .css-bg1rzq-control {
                height: 60px;
                border-radius: 10px;
            }
            // .css-kj6f9i-menu{
            //     background: #321455;
            //     color:#fff
            // }
        }
    }
}

.sideMenu .app-header{
    width: calc(100% - 0px - 250px);
}
