@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
@import "~bootstrap/scss/bootstrap";

a {
    color: inherit;
    text-decoration: inherit;
}
a:hover {
    color: inherit;
    text-decoration: none;
}

img {
    max-width: 100%;
    height: auto;
}

div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
header,
footer,
section,
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
::placeholder {
    opacity: 1;
    color: #000 !important;
}
body {
    background-color: #ebeef3;
}
// .app-footer {
//     // display: none;
// }
.text-nowrap {
    white-space: nowrap;
}
.app-main-container {
    padding-top: 100px;
}
.sideMenu .app-title {
    width: calc(100% - 0px - 250px);
}
.app-title {
    background: #684c96;
    text-align: right;
    padding: 50px 21px 25px 25px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    width: calc(100% - 0px - 140px);
    z-index: 999;
    top: 0;
    h1 {
        margin: 0;
        font-size: 22px;
        font-weight: 400;
        color: #fff;
    }
    .title-super-admin {
        background: #321455;
        padding: 6px 10px;
        align-items: center;
        border-radius: 10px;
        line-height: normal;
        margin-left: 15px;
    }
}

.app-main-content {
    // background: #684c96;
    .app-wrapper {
        padding: 0;
        width: auto;
        background: #ebeef3;
        border-radius: 10px;
        // margin: 15px;
        // border-radius: 22px;
        // min-height: 84vh;
        .main-dashboard {
            padding: 30px;
            padding-top: 80px;
        }
    }
}
::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px #5c4388;
    border-radius: 0;
    background-color: #efefef;
}

::-webkit-scrollbar {
    width: 2px;
    background-color: #5c4388;
}

::-webkit-scrollbar-thumb {
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 6px #5c4388;
    background-color: #321455;
}
.bg-gray {
    background: #e6eef3;
}
.rounded-10 {
    border-radius: 10px !important;
}
.bg-purple {
    background: #321455;
}
.ReactModal__Overlay--after-open {
    z-index: 1050;
}
.register-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    .modal-content {
        margin: auto !important;
        padding: 20px 15px;
        border-radius: 10px;
        .modal-header {
            padding: 0;
            border-bottom: none;
            h4.modal-title {
                font-size: 30px;
                color: #684d95;
                text-align: center;
                width: 100%;
                display: block;
            }
        }
        .modal-footer {
            padding: 0;
            width: 100%;
            display: block;
            border-top: none;
            button.btn {
                // background: #413464;
                border: none;
                // width: 120px;
                padding: 12px 15px;
                border-radius: 10px !important;
                // text-transform: uppercase !important;
            }
        }
    }
    input {
        &::placeholder {
            color: #495057;
            font-size: 16px;
            opacity: 70%;
        }
    }
}
.invite-modal {
    .error {
        height: auto;
        text-align: left;
    }
    .mail-icon {
        position: absolute;
        top: 7px;
        right: 12px;
        color: #ccc;
    }
}
.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.select {
    .css-1szy77t-control,
    .css-bg1rzq-control {
        height: 60px;
        border-radius: 10px;
    }
    // .css-kj6f9i-menu{
    //     background: #321455;
    //     color:#fff
    // }
}

/* splash-page */
.splash-page {
    width: 100%;
    background: #e6eef3;
    padding: 30px 0;
    .border-bottom {
        border-bottom: 1px solid #d3d9e3 !important;
    }
    .collapse-nav {
        padding-bottom: 10px;
        // border-bottom: 1px solid #d3d9e3;
        .icon {
            color: #fff;
            font-size: 99px;
        }
        .burger-menu {
            position: relative;
            display: inline-block;
            .btn {
                margin: 0;
                background: #694e96;
            }
            .burger-dropdown {
                position: absolute;
                right: 0;
                top: 65px;
                background: #694e96;
                min-width: 215px;
                z-index: 999;
                &::after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-bottom: 15px solid #694e96;
                    position: absolute;
                    top: -13px;
                    right: 9px;
                }
                .icon {
                    color: #fff;
                    margin: 10px 7px;
                }
                .lang-select {
                    position: relative;
                    .lang-icon {
                        position: absolute;
                        z-index: 9999;
                        top: 9px;
                        left: 31px;
                    }
                    select {
                        appearance: none;
                        -moz-appearance: none;
                        -webkit-appearance: none;
                        background-color: #684d95;
                        border: none;
                        padding-left: 64px;
                        background-color: #2d1e52;
                        background-image: url(../images/down-arrow.png) !important;
                        background-size: 16px !important;
                        background-repeat: no-repeat !important;
                        background-position: right 10px center !important;
                        cursor: pointer;
                        &:focus {
                            outline: none;
                        }
                        option{
                            cursor: pointer;
                        }
                    }
                    .fa-sort-down {
                        position: absolute;
                        right: 17px;
                        top: 7px;
                        color: #fff;
                        font-size: 20px;
                    }
                    .icon {
                        position: absolute;
                    }
                }
                li {
                    a,
                    select {
                        color: #fff;
                        font-size: 16px;
                        text-transform: uppercase;
                        display: block;
                        text-align: left;
                        padding: 10px;
                        border-bottom: 1px solid #e6eef3;
                        padding-left: 30px;
                        width: 100%;
                        .icon {
                            margin: 0;
                            max-width: 24px;
                            height: 24px !important;
                            margin-right: 8px;
                        }
                    }
                }
            }
        }
    }
    .splash-form {
        max-width: 520px;
        margin: 30px auto;
        .splash-logo {
            margin: 80px auto 50px auto;
            max-width: 220px;
        }
        .splash-title {
            margin-bottom: 50px;
            h4 {
                font-size: 36px;
                font-weight: 300;
                color: #2d1e52;
                text-align: center;
            }
        }
        .splash-btn {
            width: 100%;
            border: 2px solid #684d95;
            height: 52px;
            font-size: 24px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #684d95;
            background: transparent;
            text-transform: uppercase;
            font-weight: 500;
            // &:hover{
            //     color: #fff;
            //     background: #684d95;
            // }
        }
        .splash-btn.login {
            background: #684d95;
            color: #fff;
        }
    }
}

/* tas page style */
.member-tabs {
    .react-tabs__tab-list {
        border-bottom: none;
        display: flex;
        width: 100%;
        justify-content: center;
        margin-bottom: 40px;
        position: relative;
        .react-tabs__tab {
            border: none;
            width: 240px;
            background: #d8d9e3;
            font-size: 17px;
            color: #928fa4;
            margin-left: 10px;
            height: 40px;
            border-radius: 10px;
            text-align: center;
            line-height: 1.7;
            position: relative;
            &:focus {
                box-shadow: none;
            }
            &:first-child {
                margin-left: 0;
            }
        }
        .react-tabs__tab--selected {
            background: #321455;
            color: #fff;
            &::after {
                content: "";
                position: absolute;
                bottom: -10px;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-left: 9px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid #321455;
                background: transparent;
            }
            &:focus::after {
                background: transparent;
            }
        }
    }
    .setting-btn {
        background: #533d78;
        margin: 0;
        color: #fff;
        margin-left: 30px;
        margin-top: 2px;
        border-radius: 10px !important;
        padding: 8px;
        line-height: normal;
        position: absolute;
        right: 0;
        .icon {
            height: 24px !important;
            width: 24px !important;
        }
    }
    .sorting {
        color: #998aba;
        cursor: pointer;
        &:hover {
            color: #684c96;
        }
    }
}

/* Member overview  style tab */
.member_boxes {
    //  margin-bottom: 30px;
    .bg_green {
        background: #00a600;
    }
    .bg_red {
        background: #ff0000;
    }
    .bg_brown {
        background: #582600;
    }
    .bg_grey {
        background: #949494;
    }
    .bg_purple {
        background: #684c96;
    }
    .single-box {
        padding: 40px 25px;
        border-radius: 10px;
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        @media screen and (max-width: 1599px) {
            padding: 26px 15px;
        }
        @media screen and (max-width: 1199px) {
            margin-top: 20px;
        }

        .info {
            border-right: 1px solid #ebeef3;
            padding: 15px 15px 15px 0;
            width: 50%;
            @media screen and (max-width: 1599px) {
                width: 60%;
            }
            h4 {
                color: #ffff;
                font-size: 20px;
                font-weight: 400;
                @media screen and (max-width: 1599px) {
                    font-size: 18px;   
                   }
            }
            h2 {
                font-size: 26px;
                color: #fff;
                @media screen and (max-width: 1599px) {
                    font-size: 22px;   
                   }
            }
        }
        .member-number {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 1599px) {
                width: 40%;   
               }
            h2 {
                font-size: 50px;
                color: #fff;
                @media screen and (max-width: 1599px) {
                 font-size: 40px;   
                }
            }
        }
    }
}
.member-chart {
    .chart-title {
        h2 {
            display: inline-block;
            margin-top: 30px;
            margin-left: 25px;
            font-size: 22px;
            span {
                display: inline-block;
                background: #ebeef3;
                padding: 5px;
                border-radius: 10px;
                color: #684c96;
                margin-left: 6px;
                font-size: 22px;
            }
        }
    }
}
.data-range-select {
    label {
        white-space: nowrap;
        margin-top: 18px;
        display: inline-block;
        color: #684c96;
        font-weight: 400;
    }
    input.form-control {
        height: 50px;
    }
}
.member-records-table {
    .top_bar {
        margin: 25px 0;
        ul {
            li {
                margin-left: 10px;
                color: #000;
                font-weight: 400;
                font-size: 16px;
                display: inline-block;
                position: relative;
                .remove_sec {
                    margin: 0 8px;
                    background: #d3d6da;
                    padding: 7px 12px;
                    border-radius: 10px;
                    .delete_icon {
                        margin-top: -6px;
                    }
                }
                .entries-select {
                    display: flex;
                    align-items: center;
                    select {
                        margin: 0 5px;
                        border-radius: 10px;
                        &:focus {
                            outline: none;
                            box-shadow: none;
                            border-color: #321455;
                        }
                    }
                    span {
                        color: #321455;
                        white-space: nowrap;
                    }
                }
                .export-btn {
                    background: #684c96;
                    padding: 10px 15px;
                    color: #fff;
                    border-radius: 10px !important;
                    height: 40px;
                }
                .search_input {
                    width: 220px;
                    border-radius: 10px;
                    height: 40px;
                    padding-right: 40px;
                    &:focus {
                        outline: none;
                        box-shadow: none;
                        border-color: #321455;
                    }
                }
                .sreach_icon {
                    position: absolute;
                    right: 14px;
                    top: 13px;
                }
            }
        }
        .success_record {
            color: #00a600;
        }
    }
    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        // box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);
        border-radius: 10px;
        background: #fff;
        padding: 12px 0 20px;
        table {
            color: #684c96;
            th,
            td {
                padding: 15px;
                border: none;
                border-bottom: 1px solid #efefef;
                white-space: nowrap;
            }
            th {
                font-weight: bold;
            }
            tbody {
                border: none !important;
            }
        }
    }
    .pagination {
        justify-content: flex-end !important;
        margin-right: 15px;
        .page-link {
            color: #684c96;
            border: 1px solid #d8d9e3;
            border-radius: 50%;
            margin-left: 10px;
            height: 35px;
            width: 35px;
            display: flex;
            align-items: center;
        }
        .page-item.active .page-link {
            background-color: #684c96;
            border-color: #684c96;
            color: #fff;
        }
    }
    .fa-sort-down {
        font-size: 24px;
        color: #684c96;
    }
    .collapse {
        background: #ebeef3;
    }
    .tbody_style {
        background: #ebeef3;
    }
    .tbody_style .fa-sort-down {
        transform: rotate(180deg);
    }
}

.signin-page {
    .title {
        // position: absolute;
        // top: 39px;
        h2 {
            font-size: 28px;
            font-weight: 400;
            color: #2d1e52;
            @media screen and (max-width: 575px) {
                font-size: 22px;
            }
        }
    }
    .logo {
        max-width: 220px;
        margin: auto;
    }
    .already-text {
        text-align: center;
        margin-top: 30px;
        font-size: 18px;
        color: #4b65af;
        a {
            color: #9b3687;
            text-transform: uppercase;
            text-decoration: underline;
        }
    }
    .app-login-content {
        padding: 0 80px 20px 80px;
        width: 100%;
        order: 1;
        border-left: 1px solid #d3d9e3;
        @media screen and (max-width: 991px) {
            padding: 0 10px 20px 30px;
        }
        @media screen and (max-width: 767px) {
            padding: 0;
            border-left: none;
            margin-top: 30px;
        }
        .app-login-header {
            text-align: center;
            h1 {
                font-size: 26px;
                text-align: center;
                display: inline-block;
                border-bottom: 3px solid #2d1e52;
                color: #2d1e52;
            }
        }
        .app-login-form {
            .form-group {
                position: relative;
                margin-bottom: 34px;
                .field {
                    border: 1px solid #a79dc4;
                    border-radius: 10px;
                    height: 54px;
                    padding-left: 20px;
                    padding-right: 40px;
                    color: #684d95;
                    background: transparent;
                    &::placeholder {
                        color: #c7d3e5 !important;
                        font-size: 18px;
                    }
                }
                .field:-webkit-autofill,
                .field:-webkit-autofill:hover,
                .field:-webkit-autofill:focus,
                .field:-webkit-autofill:active {
                    -webkit-box-shadow: 0 0 0 30px #e6eef3 inset !important;
                }
                label {
                    display: inline-block;
                    background: #e6eef3;
                    position: absolute;
                    top: -10px;
                    left: 20px;
                    padding: 0 5px;
                }
                .icon {
                    position: absolute;
                    top: 35%;
                    right: 10px;
                    transform: translateY(-35%);
                    width: 24px !important;
                    color: #cfd4e5;
                }
            }
            button {
                width: 80%;
                margin: auto;
                height: 52px;
                border-radius: 10px;
                background: #2d1e52;
                color: #fff;
                font-size: 18px;
            }
            .forget-pwd {
                a {
                    color: #9b3687;
                    text-transform: uppercase;
                    text-decoration: underline;
                }
            }
            .add-org {
                margin-left: auto;
                background: #684c96;
                width: 40px;
                height: 40px;
                align-items: center;
                justify-content: center;
                display: flex;
                border-radius: 50%;
                color: #fff;
                border: none;
                cursor: pointer;
                position: absolute;
                right: -50px;
                top: 6px;
            }
        }
        .custom-checkbox .checkmark {
            top: 5px;
        }
        .hyper a {
            text-transform: uppercase;
            text-decoration: underline;
            color: #9b3694;
        }
    }
}

/* custom checkbox */
.custom-checkbox {
    display: block !important;
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    padding-left: 35px !important;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16x;
    font-weight: 500;
    color: #684d95;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        border: 2px solid #684d95;
        border-radius: 4px;
        &::after {
            content: "";
            position: absolute;
            display: none;
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}
.custom-checkbox:hover input ~ .checkmark {
    background-color: #e6eef3;
}
.custom-checkbox input:checked ~ .checkmark {
    background-color: #684d95;
}
.custom-checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* header 3 style */
#header3 {
    padding: 8px 0;
    background: #694e96;
    .burger-menu {
        .btn {
            background: #2d1e52;
        }
        .burger-dropdown {
            background: #2d1e52;
            &::after {
                border-bottom: 15px solid #2d1e52;
            }
            .lang-select select {
                background: #2d1e52;
            }
        }
    }
    .logo {
        max-width: 88px;
    }
    .collapse-nav {
        border-bottom: none;
        padding-bottom: 0;
        .user-name {
            font-size: 16px;
            color: #fff;
            // text-transform: uppercase;
            @media screen and (max-width: 575px) {
                width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

/* landing-page style */
.landing-page {
    width: 100%;
    display: block;
    #header3 {
        position: fixed;
        background: transparent;
        top: 0;
        z-index: 999;
        .burger-menu {
            .btn {
                background: #694e96;
            }
            .burger-dropdown {
                background: #694e96;
                &::after {
                    border-bottom: 15px solid #694e96;
                }
                .lang-select select {
                    background: #694e96;
                }
            }
        }
    }
    .hero-section {
        padding-top: 10em;
        background: url(../images/hero-bg1.jpg) no-repeat;
        background-size: cover;
        .hang-img {
            position: relative;
            max-width: 350px;
            @media screen and (max-width: 991px) {
                margin-top: 50px;
            }
            // img {
            //     max-width: 350px;
            // }
            .gif-img {
                position: absolute;
                left: 0;
                z-index: 1;
            }
            .overlay-img {
                position: relative;
                z-index: 2;
            }
        }
        .hero-content {
            h2 {
                font-size: 34px;
                color: #fff;
                font-weight: 500;
                margin-bottom: 24px;
            }
            p {
                font-size: 18px;
                color: #fff;
            }
            .download-app {
                margin-top: 50px;
                h4 {
                    font-size: 20px;
                    font-weight: 500;
                    color: #fff;
                    margin-bottom: 15px;
                }
            }
        }
    }
    .section2 {
        padding: 5em 0;
        .section-content {
            h2 {
                font-size: 30px;
                font-weight: 500;
                color: #6e409a;
                margin-bottom: 13px;
            }
            p {
                line-height: 1.5;
                margin-bottom: 15px;
                a {
                    color: #6e409a;
                    text-decoration: underline;
                    font-weight: 500;
                }
            }
        }
    }
}

.landing-page.scroll {
    #header3 {
        background: #6e409a;
        .burger-menu {
            .burger-dropdown {
                background: #2d1e52;
                &:after {
                    border-bottom: 15px solid #2d1e52;
                }
                .lang-select select {
                    background-color: #2d1e52;
                    background-image: url(../images/down-arrow.png);
                    background-size: 16px;
                    background-repeat: no-repeat;
                    background-position: right 10px center;
                }
            }
            .btn {
                background: #2d1e52;
            }
        }
    }
    #dashboard_slider {
        padding-bottom: 40px;
        .carousel-indicators li {
            background-color: #6e409a;
            width: 15px;
            height: 15px;
            border-radius: 50%;
        }
    }
}

/* contact style */

.main-form {
    .contact-content {
        margin: 30px 0 20px 0;
        h1 {
            font-size: 34px;
            text-align: center;
            text-transform: capitalize;
            font-weight: 500;
            color: #694e96;
            margin-bottom: 15px;
        }
    }
    form {
        max-width: 800px;
        margin: auto;
        color: #2d1e52;
        input::placeholder,
        textarea::placeholder {
            color: #495057;
            font-size: 16px;
            opacity: 70%;
        }
        .form-group {
            .col-md-4 {
                @media screen and (max-width: 767px) {
                    margin-bottom: 1rem;
                }
            }
        }
        input,
        textarea,
        select {
            &:focus {
                box-shadow: none;
                border-color: #694e96;
            }
        }
        .btn-send {
            background: #694e96;
            width: 80%;
            margin-top: 30px;
            margin-bottom: 20px;
            padding: 10px 0;
            color: #ffff;
            font-size: 18px;
            text-transform: uppercase;
            border-radius: 10px;
            border: 1px solid #694e96;
            &:hover {
                background: #2d1e52;
                border: 1px solid #2d1e52;
            }
        }
    }
}

/* account page */
.account-page {
    .account-info {
        // min-height: 300px;
        .sub-heading {
            font-size: 27px;
            color: #2d1e52;
            font-weight: 500;
        }
        h2 {
            font-size: 26px;
        }
        .edit-icon {
            margin-left: auto;
            background: #684c96;
            width: 40px;
            height: 40px;
            align-items: center;
            justify-content: center;
            display: flex;
            border-radius: 50%;
            color: #fff;
            border: none;
            cursor: pointer;
        }
        .detail {
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid #c9cdd0;
            .field {
                display: block;
                font-size: 20px;
                margin-bottom: 15px;
            }
            form {
                max-width: 550px;
                @media screen and (max-width: 1199px) {
                    max-width: 100%;
                }
            }
        }
        .card {
            font-size: 20px;
            border-radius: 10px;
            background: #fff;
            .account-name,
            .account-id {
                span {
                    color: #000000;
                    font-weight: 500;
                    margin-left: 10px;
                }
            }
            .account-name label,
            .account-id label {
                color: #7b838a;
            }
            .trash-icon {
                font-size: 26px;
                color: #ca2828b8;
                cursor: pointer;
            }
        }
        .col {
            flex: 0 0 33.3%;
            max-width: 33.3%;
            @media screen and (max-width: 1500px) {
                flex: 0 0 50%;
                max-width: 50%;
            }
            @media screen and (max-width: 1199px) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }

    .btn-confirm {
        background: #684c96;
        padding: 10px 40px;
        color: #fff;
        &:hover {
            color: #fff;
        }
    }
    .delete-link {
        color: #684c96;
        text-decoration: underline;
        font-size: 20px;
        padding: 0;
        border: none;
        background: none; 
    }
    .account-heading {
        h2 {
            font-size: 28px;
        }
        .delete-icon {
            margin-left: 20px;
            background: #684c96;
            width: 40px;
            height: 40px;
            align-items: center;
            justify-content: center;
            display: flex;
            border-radius: 50%;
            color: #fff;
            border: none;
            cursor: pointer;
        }
    }
    // .users-inner {
    //     .card {
    //         min-height: 168px;
    //     }
    // }
    .account-heading.border-b {
        border-bottom: 2px solid #ded6e6;
    }
    // .btn-resend {
    //     background: #684c96;
    //     color: #fff;
    //     margin-left: 5px;
    // }
    // .border-right {
    //     border-right: 3px solid #ded6e6 !important;
    // }
}

.notification-contant {
    .alert {
        border: none;
        border-left: 3px solid #b3abab;
        margin-bottom: 20px;
        .fa-times {
            font-size: 18px;
        }
    }
}
.btn-submit1 {
    width: 150px;
    margin: auto;
    height: 52px;
    border-radius: 10px;
    background: #2d1e52;
    color: #fff;
    &:hover {
        color: #fff;
    }
}
.bg-blue1 {
    background: #2d1e52;
}

.user-recods-table {
    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        border-radius: 10px;
        background: #fff;
        padding: 12px 0 20px;
        .table {
            th,
            td {
                padding: 18px 40px;
                border-top: none;
            }
            .fa-trash-alt {
                color: #ea404e;
                cursor: pointer;
            }
        }
        thead {
            padding: 0 100px;
        }
        .btn-resend {
            background: #ebeef3ab;
        }
    }
    .owner {
        background: #f5f2f8;
    }
    .accepted {
        background: #feffff;
    }
    .pending {
        background: #fffbf4;
    }
    .overview {
        background: #fafaf0;
    }
}
.m-icon {
    position: absolute;
    right: 9px;
    top: 7px;
    opacity: 40%;
}
.btn-purple {
    background: #684c96;
    color: #fff;
    &:hover {
        background: #5c4388;
        color: #fff;
    }
}

.badge-msg {
    position: absolute;
    left: 0;
    top: -7px;
}
.mt-20 {
    margin-top: 20%;
}
.mt-10 {
    margin-top: 10%;
}
.min-height-430 {
    min-height: 430px;
}
footer {
    text-align: center;
    width: 100%;
    background: #fff;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        max-width: 120px;
        margin-left: 15px;
    }
}
.form-control:disabled,
.form-control[readonly] {
    background-color: #e6eef3;
}

.modal-header .close {
    color: #fff;
    font-size: 26px;
    background: rebeccapurple;
    opacity: 1 !important;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    right: 10px;
    padding: 0;
    top: 4px;
}
.modal-header .close:hover {
    opacity: 1 !important;
}
.modal-header .close span{
   height: 30px;
}
.login-notifation {
    background: #9b3687a6;
    padding: 8px 12px;
    border-radius: 10px;
    margin-bottom: 24px;
    color: #fff;
    text-align: center;
    box-shadow: 1px 1px 5px 0.1px #888888;
}
.form-control::placeholder {
    color: #9c9d9e !important;
    opacity: 1;
}
.cookies{
    .cookies_toggle_btn {
        position: fixed;
        bottom: 10px;
        background: #2d1e52;
        right: 15px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        overflow: hidden;
        color: #fff;
        min-width: 48px;
        &:hover{
        background: #4a276a;
        }
    }
}
.sideMenu .cookies {
    width: calc(100% - 0px - 250px);
}
.data-range-select1{
    max-width: 449px;
    display: flex;
    margin: auto;
    width: 100%;
    align-items: center;
}
.data-range-select1 .select {
    width: 100%;
    margin-left: 15px;
}
.data-range-select1 .select .css-1szy77t-control, .data-range-select1 .select .css-bg1rzq-control {
    height: 50px;
}
.data-range-select1 label {
    margin: 0;
}

.org-inputs-group input {
    width: 50px !important;
    height: 50px;
    font-size: 20px;
    border: 1px solid #684c96;
    border-radius: 2px;
}
.org-inputs-group input:focus {
    outline-color: #684c96;
}
.org-inputs-group div {
    justify-content: center;
}
.org-inputs-group {
    margin-top: 15px;
}
.org-inputs-group span {
    visibility: hidden;
}

.org-inputs-group .error {
    text-align: center;
    margin-top: 10px;
}


.faq-main-banner {
    padding: 98px 0px 40px 0px;
}
.acc-content {
display: none;
}
@media (min-width: 480px) {
    .faq-main-banner {
        padding: 115px 0px 40px 0px;
    }
}

@media (min-width: 768px) {
    .faq-main-banner {
        padding: 130px 0px 60px 0px;
    }
}

@media (min-width: 992px) {
    .faq-main-banner {
        padding: 163px 0px 80px 0px;
    }
}

.faq-main-banner h2 {
    color: #0D152E;
    font-size: 36px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: -1px;
    line-height: normal;
}

@media (min-width: 576px) {
    .faq-main-banner h2 {
        font-size: 40px;
    }
}

@media (min-width: 768px) {
    .faq-main-banner h2 {
        font-size: 48px;
    }
}

@media (min-width: 992px) {
    .faq-main-banner h2 {
        font-size: 60px;
    }
}

.faq-main-area {
    padding-bottom: 25px;
}

@media (min-width: 768px) {
    .faq-main-area {
        padding-bottom: 15px;
    }
}

@media (min-width: 992px) {
    .faq-main-area {
        padding-bottom: 45px;
    }
}

.faq-main-tab-area ul li a {
    text-decoration: none;
    color: #0D152E;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.53px;
    line-height: normal;
    display: inline-block;
    margin-bottom: 23px;
}

.faq-main-tab-area ul li.active a {
    color: #694e96;
    font-weight: 700;
}

.faq-single-all-items {
    margin-bottom: -60px;
    padding-top: 10px;
}

@media (min-width: 768px) {
    .faq-single-all-items {
        padding-top: 20px;
    }
}

@media (min-width: 992px) {
    .faq-single-all-items {
        padding-top: 0;
    }
}

.faq-main-area-single {
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .faq-main-area-single {
        margin-bottom: 40px;
    }
}

@media (min-width: 992px) {
    .faq-main-area-single {
        margin-bottom: 60px;
    }
}

.faq-main-area-single h2 {
    color: #694e96;
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    line-height: normal;
    margin-bottom: 30px;
    text-transform: uppercase;
}

.faq-main-area-single .accordion li {
    position: relative;
    list-style: none;
    border-radius: 5px;
    border: 1px solid #c5c5c5;
    padding: 15px 20px 15px 15px;
    margin-bottom: 10px;    

}
.faq-main-area-single .collapse, .faq-main-area-single .collapsing {
    margin-top: 20px;
}
.faq-main-area-single .accordion a[aria-expanded="true"] {
    text-decoration: underline !important;
}
.submenu .dropdown-menu {
    position: relative !important;
    transform: translate(0) !important;
    top: auto !important;
    width: 100%;
    border-radius: 0;
    background: transparent;
    padding-top: 0;
    box-shadow: none;
    border: none;
    float: none;
}
.submenu .dropdown-menu .dropdown-item,.submenu .dropdown-menu .dropdown-item:hover{
    padding-left: 60px !important;
    background: transparent;;
}
.burger-menu .burger-dropdown ul {
    min-width: 242px;
}
.submenu .dropdown-toggle{
    position: relative;
    padding-right: 20px;
}
.submenu .dropdown-toggle::after {
    border-top: 7px solid;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.faq-main-area-single .accordion li.active {
    background: #f8fafc;
}

.faq-main-area-single .accordion li p {
    display: none;
    padding: 20px 15px 0px 0px;
    color: #687497;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.53px;
    line-height: 28px;
    margin-bottom: 0;
}

.faq-main-area-single .accordion a {
    width: 100%;
    display: block;
    cursor: pointer;
    text-decoration: none;
    color: #0D152E;
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    line-height: normal;
    position: relative;
    padding-right: 20px;
}

.faq-main-area-single .accordion li .active {
    color: #0D152E !important;
}

.faq-main-area-single .accordion .active:after {
    border-right: 2px solid #694e96 !important;
    border-bottom: 2px solid #694e96 !important;
}
.faq-main-area-single .accordion ol li {
    border: none;
    list-style: auto;
    padding: 5px 0;
    margin-bottom: 5px;
}

.faq-main-area-single .accordion a:after {
    width: 8px;
    height: 8px;
    border-right: 2px solid #694e96;
    border-bottom: 2px solid #694e96;
    position: absolute;
    right: 0px;
    content: " ";
    top: 8px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transition: all 0.2s ease-in-out;
}
// FAQ CSS 
.faq-main-area-single .accordion a.active:after {
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    transition: all 0.2s ease-in-out;
}

.faq-video {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio */
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.faq-sidebar {
    position: sticky;
    top: 43px;
    background: #d5d9dc;
    color: #2d1e52;
    padding: 20px;
    border-radius: 10px;
    margin-top: 60px;
}
.faq-sidebar ul li {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
    text-transform: uppercase;
}
.faq-sidebar ul li a{ 
    color: #2d1e52;
    &:hover {
        color: #515052;
    }
}
.faq-main-area-single .accordion .faq-link:after {
    content: none;
}
.faq-main-area-single .accordion .faq-link{
    display: inline-block;
    width: auto;
    font-weight: 500;
    color: #70579c;
    padding-right: 0;
}
#register-form label {
    position: relative;
    left: 0;
    top:0;
}
#app-register-header{
    background: #e3e7ef;
    padding: 10px;
    border-radius: 4px;
}
#register-form .form-group {
    margin-bottom: 28px;
}
#register-form .form-group .icon{
top: 65%;
right: 10px;
transform: translateY(-65%);
}
#app-register-header span {
    font-weight: 500;
}
.btn-apply{
    background: #ebeef3;
    margin-left: 15px;
    border-radius: 7px !important;
    padding: 8px 12px;
    margin-bottom: 0;
    text-transform: uppercase !important;
    font-weight: 500 !important;
}
.joinNow-form h2 {
    font-size: 25px;
    color: #694e96;
    padding: 15px 20px;
    text-align: center;
}
.joinNow-form .app-login-content {
    padding: 20px 25px 5px;
}
.joinNow-form .form-group{ 
   position: relative;
}
.joinNow-form .form-control{
    border: 1px solid #a79dc4;
    border-radius: 10px;
    height: 46px;
    padding-left: 20px;
    padding-right: 40px;
    color: #684d95;
    background: transparent;
}
.joinNow-form .icon {
    position: absolute;
    top: 35%;
    right: 10px;
    transform: translateY(-35%);
    width: 24px !important;
    color: #cfd4e5;
}
.joinNow-form .btn-regiter,.joinNow-form .btn-regiter:hover{
    width: 100%;
    margin: auto;
    height: 46px;
    border-radius: 10px;
    background: #2d1e52;
    color: #fff !important;
    font-size: 18px;
}
.joinNow-form .btn-regiter[disabled]{
    background: #cfd4e5
}
.ReactModal__Body--open{
    overflow: hidden;
}
.joinNow-form .close {
    position: absolute;
    top: -12px;
    right: -10px;
    background: #694e96;
    width: 30px;
    height: 30px;
    font-size: 20px;
    opacity: 1;
    color: #fff;
    border-radius: 50%;
    span{
       top: -2px;
       position: relative;
       height: auto;
    }
}
.joinNow-form .close:hover{
    opacity: 1 !important;
}
.joinNow-form .modal-dialog{
    max-width: 550px;
}
.success-msg{
    font-size: 20px;
}
.account-info .appynew-btn{
    background: #321455;
    padding: 10px 15px;
    font-size: 16px !important;
    border-radius: 10px !important;
    text-transform: initial !important; 
    // border: 1px solid rgb(50 20 85);
    color: #fff;
    .icon-add {
        background: #ebeef3;
        color: #684c96;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        margin-right: 8px;
    }
}
.processed-table {
    .container{
      @media screen and(min-width: 1200px){
        max-width: 1400px;
      }
    }
    #as-react-datatable-table-body{
        .col-md-12{
            display: block;
            width: 100%;
            overflow-x: auto;
            &::-webkit-scrollbar {
                width: 2px;
                height: 4px;
            }
        }
       
    }
    margin-top: 50px;
    margin-bottom: 30px;
    h2 {
        font-size: 34px;
        margin-bottom: 20px;
        font-weight: 500;
        color: #321455;
    }
    .table_filter{
        input{
            &:focus{
                outline: none;
                box-shadow: none;
                border-color: #684c96;
            } 
        }
    }
    .asrt-page-length {
        .input-group-addon {
          background: transparent;
        }
        select.form-control {
            border-radius: 5px !important;
            &:focus{
                outline: none;
                box-shadow: none;
                border-color: #684c96;
            }

        }
    }
    .asrt-table-head {
        margin-bottom: 36px;
    }
    table{
        border: none;
        background: #fff;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 18px 0px;
        border-radius: 10px;
        // text-align: center;
        margin-bottom: 40px;
        thead{
            th{
               background-color: #321455;
               color: #fff;
               border: none;
               font-weight: 400;
               padding: 18px 6px;
               white-space: nowrap;
               &:first-child{
                   border-top-left-radius: 10px;
                   padding-left: 15px;
               }
               &:last-child{
                border-top-right-radius: 10px;
                padding-right: 15px;
               }  
            }
        }
        tbody{   
            tr{            
            td{
              color: #321455;
              border: none;
            //   padding-left: 5px;
            //   padding-right: 5px;
              font-size: 15px;
              padding-left: 6px;
              padding-right: 6px;
              &:first-child{
                  white-space: nowrap;
                  padding-left: 15px;
                }
                &:last-child{ 
                    padding-right: 15px; 
                }
            }
        }
        }
         .status-in-process,.status-registered{
            display: inline-block;
            text-transform: uppercase;
        }
        .status-registered {
            color: #3330e0;
        }
    }
    .asrt-pagination{
      .page-item{
          &:first-child{
              display: none;
          }
          &:last-child{
            display: none;
        }
        &:nth-child(3) .page-link{
            background: #684c96;
            &:hover{
            background: #684c96;   
            }
            input{
                background: #684c96;
                color: #fff;
                pointer-events: none;
            }
        }
        .page-link {
            color: #684c96;
            &:focus{
                outline: none;
                box-shadow: none;
            }
            &:hover{
                background: #fff;
            }
        }
      }
      .page-item.disabled .page-link{
        color: #6c757d;
      }
    }
}


@media screen and (max-width:767px){
    .faq-sidebar {
        display: none;
    }
}

.bg-purple1 {
    background: #bca5e1;
}
.table-delete-icon {
    background: #dfd8e7;
}
.delete-link1{
    color: #684c96;
    text-decoration: underline;
    font-size: 17px;
    padding: 0;
    border: none;
    background: none; 
    cursor: pointer;
    text-transform: uppercase;
}
.invited_list .table-action .btn-success{
    padding: 2px 7px;
    width: 78px;
}
#cookie_modal{ 
.react-tabs__tab-list { 
        margin-bottom: 25px;
        border-bottom: 1px solid #d6d6d6;
        padding-bottom: 1px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    li {
        text-align: center;
        border: none;
        font-weight: 500;
        box-shadow: none;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .react-tabs__tab--selected {
        border-bottom: 3px solid rebeccapurple;
        color: #684d95;
        box-shadow: none;
        &:focus::after{
          content: none;
        }
    }
}
.current_state_content {
   .icon {
        width: 24px !important;
        height: 24px !important;
    }
    .hide_detail{
        background: #F2F2F2;
        padding: 20px;
        border-radius: 5px;
        margin-top: 20px;
        word-break: break-all;
        font-size: 14px;
        label {
            font-weight: 500;
        }
    }
}
h4 {
    font-size: 18px;
    margin-bottom: 5px;
}
.react-tabs__tab-panel {
    // height: 170px;
    overflow-x: auto;
}
.cookie_buttons{
    margin-top: 20px;
    border-top: 1px solid #d6d6d6;
    padding-top: 10px;
   button {
        margin: 1%;
        background: transparent;
        border: 2px solid rebeccapurple;
        color: rebeccapurple;
        padding: 7px 20px;
        &:hover{
            background: rebeccapurple;
            color: #fff;
         }
    }
    button.active{
        background: rebeccapurple;
        color: #fff;
     }
}

.cookie_about_tab{  
    P {
        margin-bottom: 15px;
    }
}

.powered_by{
        margin-bottom: 20px;
        border-bottom: 1px solid #d6d6d6;
        display: block;
        padding-bottom: 10px;
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        margin-top: -10px;
      .powered_logo {
        max-width: 56px;
        margin-left: 7px;
      }
}
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #663399;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #663399;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
}

.round {
    border: #4a276a 1px solid;
    border-radius: 4px;
}


.three-dots:after {
    cursor: pointer;
    color: #FCC;
    content: '\2807';
    font-size: 20px;
    padding: 0 5px;
  }
  
  a {
    text-decoration: none;
    color: white;
  }
  
  a div {
    padding: 2px;
  }
  
  .dropdown {
    background-color: #66D;
    box-shadow: 0px 0px 3px 3px #66D;
    outline: none;
    opacity: 0;
    z-index: -1;
    max-height: 0;
    transition: opacity 0.1s, z-index 0.1s;
  }
  
  .dropdown-container:focus {
    outline: none;
  }
  
  .dropdown-container:focus .dropdown {
    opacity: 1;
    z-index: 100;
    max-height: 100vh;
    transition: opacity 0.2s, z-index 0.2s;
  }


.email_td {
    display: flex;
    position: relative;
    align-items: center;
	.email_name {
     width: 132px;
    }
 .dropdown-container {
    z-index: auto !important;
    .three-dots:after {
        color: #321455;
        line-height: 1.2;
    }
    .dropdown {
        position: absolute;
        background: #684c96;
        left: 0;
        box-shadow: none;
        padding: 5px 10px;
        color: #fff;
        border-radius: 4px;
    }
   }
 }

 .processed-table { 
 .asrt-pagination .page-item .page-link {
    color: #684c96 !important;
    background: #f2f2f2 !important;
    border-color: #684c96 !important;
    border-radius: 4px;
    &:hover{
        color: #fff !important;
        background: #684c96 !important;
        border-color: #684c96 !important;
    }
}
.buttons-csv{
    background: #321455;
    border-color: #321455;
    margin-bottom: 0 !important;
    height: 36px;
    &:hover, &:focus{
        background: #321455;
        border-color: #321455;
        margin-bottom: 0 !important;
        height: 35px;
        margin-top: 1px;
    }
    &::before {
        content: "\f019";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
    }
    i{
        display: none;
    }
}
 }
